import { GODOCU_PDF, ROOT } from '../../../../constants/routes'
import { Document, User } from './types'
import ServiceUtils from './utils'
import StorageService from './storage'

export default class DocumentsService {
  auth: any
  documents: any
  utils: ServiceUtils
  storage: StorageService

  constructor(app: any) {
    this.auth = app.auth()
    this.documents = app.firestore().collection('documents')
    this.storage = new StorageService(app)
    this.utils = new ServiceUtils(app)
  }

  prepareDocument = async (rawDocument: any): Promise<Document> => {
    const { banner, bannerUrl, contactInfo: logo } = rawDocument
    let docId = rawDocument.id || null
    const preparedDoc = { ...rawDocument }

    // Delete the banner key before creating the document to avoid the
    // unrecognized rawFile key error
    if (banner && !bannerUrl) {
      try {
        preparedDoc.bannerUrl = await this.storage.manageFile(banner, 'documents/banners', docId)
      } catch (error) {
        throw new Error(`Error uploading banner: ${error}`)
      }
      delete preparedDoc.banner
    }

    if (rawDocument.showContactInfoForm === 'no') {
      delete preparedDoc.contactInfo
    } else {
      preparedDoc.contactInfo.logoUrl = await this.storage.manageFile(
        logo,
        'documents/contactInfoLogos',
        docId
      )
      delete preparedDoc.contactInfo.logo
    }

    return preparedDoc
  }

  createDocDownloadUrl = (document: Document, documentId: string): string => {
    let downloadUrl: string
    if (document.linkSlug) {
      downloadUrl = `${ROOT}${GODOCU_PDF.replace(
        ':linkSlug/:documentId',
        `${document.linkSlug}/${documentId}`
      )}`
    } else {
      downloadUrl = `${ROOT}${GODOCU_PDF.replace(':linkSlug/:documentId', `${documentId}`)}`
    }
    return downloadUrl
  }

  insertDocument = async (rawDocument: any): Promise<Document> => {
    let insertedDoc: Document
    let preparedDoc: Document

    try {
      preparedDoc = await this.prepareDocument(rawDocument)
    } catch (error) {
      throw new Error(`Error preparing document: {$error}`)
    }

    const userId = this.auth.currentUser.uid
    let user: User
    try {
      user = await this.utils.getDocumentData('users', userId)
    } catch (error) {
      throw new Error(`Firestore Error: ${error}`)
    }

    try {
      const docsCollection = await this.documents()
      const addedRef = await docsCollection.add(preparedDoc)
      const docId = addedRef.id

      preparedDoc.createdAt = new Date()
      preparedDoc.createdBy = userId
      preparedDoc.clientId = user.clientId
      preparedDoc.url = this.createDocDownloadUrl(preparedDoc, docId)

      insertedDoc = await this.utils.postDocumentData('documents', docId, preparedDoc)
    } catch (error) {
      throw new Error(`Error inserting document: ${error}`)
    }

    return insertedDoc
  }

  updateDocument = async (rawDocument: any): Promise<Document> => {
    let updatedDoc: Document
    let preparedDoc: Document

    try {
      preparedDoc = await this.prepareDocument(rawDocument)
    } catch (error) {
      return Promise.reject(`Error preparing document: ${error}`)
    }

    preparedDoc.updatedBy = this.auth.currentUser.uid
    preparedDoc.updatedAt = new Date()
    preparedDoc.url = this.createDocDownloadUrl(preparedDoc, preparedDoc.id)

    try {
      updatedDoc = await this.utils.postDocumentData('documents', rawDocument.id, preparedDoc)
    } catch (error) {
      throw new Error(`Error updating document: ${error}`)
    }
    return updatedDoc
  }

  cloneDocument = async (originalDoc: Document): Promise<Document> => {
    let clonedDoc: Partial<Document>
    let insertedDoc: Document

    clonedDoc = { ...originalDoc }
    delete clonedDoc.id
    delete clonedDoc.updatedAt
    delete clonedDoc.updatedBy

    try {
      insertedDoc = await this.insertDocument(clonedDoc)
    } catch (error) {
      throw new Error(`Error cloning document: ${error}`)
    }
    return insertedDoc
  }

  getDocument = async (id: string): Promise<Document> => {
    let document: Document

    try {
      document = await this.utils.getDocumentData('documents', id)
    } catch (error) {
      throw new Error(`Error getting document: ${error}`)
    }
    return document
  }

  getDocuments = () => this.utils.getCollection('documents')
}
