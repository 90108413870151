import { User } from './types'
import ServiceUtils from './utils'

export default class UsersService {
  utils: ServiceUtils

  constructor(app: any) {
    this.utils = new ServiceUtils(app)
  }

  userData = async (id: string): Promise<User> => {
    let data = null
    try {
      data = await this.utils.getDocumentData('users', id)
      console.log('USER:', data)
      return data
    } catch (error) {
      console.error(error)
      return Promise.reject(`Error getting User data: ${error}`)
    }
  }

  user = async (id: string): Promise<User> => await this.utils.getDocumentData('users', id)

  users = async (): Promise<User[]> => this.utils.getCollection('users')
}
