import { Client } from './types'

export default class ClientsService {
  db: any
  auth: any
  storage: any

  constructor(app: any) {
    this.db = app.firestore()
    this.auth = app.auth()
    this.storage = app.storage()
  }

  /**
   * Get a Client by id
   *
   * @param {String} id Clients id
   *
   * @returns {Object} Client data
   */
  client = async (id: string): Promise<Client> => {
    try {
      const clientRef = await this.db.doc(`clients/${id}`)
      const client = await clientRef.get()
      return client.data()
    } catch (error) {
      return Promise.reject(`Error getting Client: ${error}`)
    }
  }

  clients = async (): Promise<Client[]> => this.db.collection('clients')

  /**
   * Updates a Client by id
   *
   * @param {Client} Client object with mutated data
   *
   * @returns {Object} Updated Client data
   */
  updateClient = async (client: Client): Promise<Client> => {
    const newClient = Object.assign(client, {
      updatedAt: new Date(),
      updatedBy: this.auth.currentUser.uid
    })

    try {
      if (client.logo) {
        const filePath = 'clients/logos'
        client.logoUrl = await this.storage.manageFile(client.logo.rawFile, filePath, client.id)
      }
      await this.db.postDocumentData('clients', client.id, newClient)
    } catch (error) {
      console.log('Could not save Client:', error)
      return Promise.reject(`Could not save Client: ${error}`)
    }
    return newClient
  }
}
