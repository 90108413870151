import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import AuthService from './services/auth'
import ClientsService from './services/clients'
import DocumentsService from './services/documents'
import ServicesService from './services/services'
import StorageService from './services/storage'
import UsersService from './services/users'
import UtilsService from './services/utils'
import { firebaseConfig } from './firebaseConfig'

export const PixelCodeApp = app.initializeApp(firebaseConfig)

export const Auth = new AuthService(PixelCodeApp)
export const Clients = new ClientsService(PixelCodeApp)
export const Documents = new DocumentsService(PixelCodeApp)
export const Services = new ServicesService(PixelCodeApp)
export const Storage = new StorageService(PixelCodeApp)
export const Users = new UsersService(PixelCodeApp)
export const Utils = new UtilsService(PixelCodeApp)
