export default class ServiceUtils {
  db: any

  constructor(app: any) {
    this.db = app.firestore()
  }

  /**
   * Wrapper function to retrieve a Firestore CollectionReference.
   *
   * @param {string} collectionName The collection to get the document from
   *
   * @memberof ServiceUtils
   *
   * @returns {CollectionReference} The documents fields/values
   */
  getCollection = (collectionName: string): any => this.db.collection(collectionName)

  /**
   * Wrapper function to retrieve a documents data.
   *
   * @param {string} collectionName The collection to get the document from
   *
   * @param {string} id The documents id
   *
   * @memberof ServiceUtils
   *
   * @returns {Object} The documents fields/values
   */
  getDocumentData = async (collectionName: string, id: string): Promise<any> => {
    try {
      const docRef = await this.db.doc(`${collectionName}/${id}`)
      const doc = await docRef.get()
      return doc.data()
    } catch (error) {
      console.error(error)
      return Promise.reject(`Could not get document data: ${error}`)
    }
  }

  /**
   * Wrapper function to post/save a documents data.
   *
   * @param {string} collectionName The collection to get the document from
   *
   * @param {string} id The documents id
   *
   * @memberof FirebaseProvider
   *
   * @returns {Object} The documents fields/values
   */
  postDocumentData = async (collectionName: string, id: string, data: any): Promise<any> => {
    data = this.cleanObject(data)
    try {
      const oldDoc = await this.db.doc(`${collectionName}/${id}`)

      await oldDoc.update(data)
      return { ...oldDoc, ...data }
    } catch (error) {
      console.error(error)
      return Promise.reject(`Could not update document: ${error}`)
    }
  }

  getBySimpleQuery = async (
    collectionName: string,
    queryComponents: { field: string; operator: string; value: any }
  ): Promise<any[]> => {
    const { field, operator, value } = queryComponents
    const collection = this.getCollection(collectionName)

    let snapshot: any
    try {
      snapshot = await collection.where(field, operator, value).get()
    } catch (error) {
      console.log(error)
      return Promise.reject(`Could not execute query: ${error}`)
    }
    let docs: any[] = []

    if (snapshot.empty) {
      return []
    }

    snapshot.forEach((doc: any) => {
      docs.push({ id: doc.id, ...doc.data() })
    })
    return docs
  }

  cleanObject = (dataObj: any) => {
    const keys = Object.keys(dataObj)
    let cleanObj = {}
    keys.forEach((key: any) => {
      if (dataObj[key] !== undefined) {
        cleanObj = { ...cleanObj, ...{ [key]: dataObj[key] } }
      }
    })
    return cleanObj
  }
}
