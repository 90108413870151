import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import * as ROUTES from '../constants/routes'

import ErrorBoundary from './components/ErrorBoundary'
import Loading from './components/Loading'
import { FirebaseServicesContext, FirebaseServices } from './providers/Firebase/context'
import SessionProvider from './providers/Firebase/Session'

const Landing = lazy(() => import('./pages/Landing'))
const Profile = lazy(() => import('./pages/Profile'))
const Admin = lazy(() => import('./pages/Admin'))
const GoDocu = lazy(() => import('./pages/GoDocu'))
const NotAuthorized = lazy(() => import('./pages/NotAuthorized'))
const SignUp = lazy(() => import('./pages/auth/SignUp'))
const SignIn = lazy(() => import('./pages/auth/SignIn'))
const Forgot = lazy(() => import('./pages/auth/Forgot'))
const AdminDashboard = lazy(() => import('./components/AdminDashboard'))
const Account = lazy(() => import('./components/Account'))
const RetrieveDocument = lazy(() => import('./components/RetrieveDocument'))

const App = () => (
  <FirebaseServicesContext.Provider value={FirebaseServices}>
    <SessionProvider>
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.PASSWORD_FORGET} component={Forgot} />
            <Route path={ROUTES.PROFILE} component={Profile} />
            <Route path={ROUTES.DASHBOARD} component={AdminDashboard} />
            <Route path={ROUTES.ACCOUNT} component={Account} />
            <Route path={ROUTES.ADMIN} component={Admin} />
            <Route exact path={ROUTES.GODOCU} component={GoDocu} />
            <Route path={ROUTES.GODOCU_PDF} component={RetrieveDocument} />
            <Route path={ROUTES.NOT_AUTHORIZED} component={NotAuthorized} />
          </Suspense>
        </ErrorBoundary>
      </Router>
    </SessionProvider>
  </FirebaseServicesContext.Provider>
)

export default App
