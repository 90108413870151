export default class StorageService {
  storage: any

  constructor(app: any) {
    this.storage = app.storage()
  }

  uploadFile = async (filePath: string, file: File) => {
    const storageRef = this.storage.ref()
    const fileRef = storageRef.child(filePath)
    try {
      await fileRef.put(file)
    } catch (error) {
      throw new Error(`Error uploading file: ${error}`)
    }
  }

  deleteFile = async (filePath: string) => {
    const storageRef = this.storage.ref()
    const fileRef = storageRef.child(filePath)
    try {
      await fileRef.delete()
    } catch (error) {
      throw new Error(`Error deleting file: ${error}`)
    }
  }

  getDownloadUrl = async (filePath: string) => {
    const storageRef = this.storage.ref(filePath)
    try {
      return await storageRef.getDownloadURL()
    } catch (error) {
      throw new Error(`Error getting download url: ${error}`)
    }
  }

  /**
   * Uploads a file to Firebase Storage and returns the full path to retrieve it
   *
   * @param {File} FileObject  File Object from a form
   * @param {string} path        The path to store the file in
   * @param {string} docId      Id of the asociated document
   *
   * @returns {string} The full path of the file in storage or null
   *
   * @memberof FirebaseProvider
   */
  manageFile = async (fileObject: any, path: string, docId: string): Promise<string> => {
    let fileUrl
    try {
      const fullPath = `${path}/${docId}`
      await this.uploadFile(fullPath, fileObject.rawFile)
      fileUrl = await this.getDownloadUrl(fullPath)
    } catch (error) {
      throw new Error(`Error managing file: ${error}`)
    }
    return fileUrl
  }
}
