import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './ui/App'
import * as serviceWorker from './serviceWorker'
import { ConfigProvider } from 'antd'
import es_ES from 'antd/lib/locale/es_ES'
import moment from 'moment'
import 'moment/locale/es'
require('dotenv').config()

moment.locale('es')

ReactDOM.render(
  <ConfigProvider locale={es_ES}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
