import React, { ErrorInfo, ReactNode } from 'react'
import { notification } from 'antd'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = { hasError: false }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // const response = await this.props.firebase.saveError(error, errorInfo)
    // if (includes(response, 'Failed')) {
    //   console.log(response)
    // }

    console.error(`ErrorBoundary reached: ${error}, ${errorInfo}`)
  }

  public render() {
    if (this.state.hasError) {
      notification.error({
        message: 'Wops!',
        description: (
          <span>
            Algo salio mal.
            <br />
            Intente nuevamente
          </span>
        )
      })
      return <></>
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
