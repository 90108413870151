import _includes from 'lodash/includes'
import { User } from 'firebase'
import UsersService from './users'
import { Session } from './types'

interface CreateUserParams {
  email: string
  password: string
  firstName: string
  lastName: string
}

export default class AuthService {
  auth: any
  usersService: UsersService
  db: any

  constructor(app: any) {
    this.auth = app.auth()
    this.db = app.firestore()
    this.usersService = new UsersService(app)
  }

  getAuthStateListener = (sessionFunc: Function) => this.auth.onAuthStateChanged(sessionFunc)

  createUser = async ({
    email,
    password,
    firstName,
    lastName
  }: CreateUserParams): Promise<void> => {
    try {
      const userCredentials = await this.auth.createUserWithEmailAndPassword(email, password)
      const userId: string = userCredentials.user.uid
      await this.db.doc(`users/${userId}`).set({
        id: userId,
        firstName,
        lastName,
        email,
        active: false,
        createdAt: new Date()
      })
    } catch (error) {
      return Promise.reject(error.message)
    }
  }

  signInWithEmailAndPassword = async (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password)

  signOut = async () => await this.auth.signOut()

  passwordReset = async (email: string) => this.auth.sendPasswordResetEmail(email)

  passwordUpdate = async (password: string) => this.auth.currentUser.updatePassword(password)

  currentUser = async (): Promise<Session> => {
    const user = this.auth.currentUser
    let session: Session
    try {
      const appUser = await this.usersService.user(user.uid)
      session = { ...user, ...appUser }
    } catch (error) {
      console.log('Error getting User', error)
      return Promise.reject(`Error al obtener usuario: ${error}`)
    }
    return session
  }

  isLoggedIn = async () => {
    let loggedIn: boolean = false
    this.auth.onAuthStateChanged((user: User) => {
      loggedIn = true
    })
    return loggedIn
  }

  isAuthorized = async (session: Session, role: string): Promise<boolean> => {
    try {
      if (!session) return false
      if (!_includes(session.roles, role) || !_includes(session.roles, 'superAdmin')) return false
    } catch (error) {
      return Promise.reject(`Error verifying User permissions: ${error}`)
    }

    return true
  }
}
