import { User } from 'firebase'
import React, { useEffect, useState } from 'react'
import { Session } from './services/types'
import { Auth, Users } from './Firebase'
import { SessionContext } from './context'
import { notification } from 'antd'

const SessionProvider = (props: any) => {
  const [authUser, setAuthUser] = useState<Partial<User>>({})
  const [session, setSession] = useState<Partial<Session>>({})

  useEffect(() => {
    const authListener = Auth.getAuthStateListener(async (user: User) => {
      user ? setAuthUser(user) : setAuthUser({})
    })

    const getUserData = async () => {
      if (authUser && authUser.uid) {
        try {
          const appUser = await Users.user(authUser.uid)
          if (appUser) {
            setSession({ ...authUser, ...appUser, id: authUser.uid })
          }
        } catch (error) {
          console.error(`Error getting user session: ${error}`)
          notification.error({
            message: 'Woops!',
            description: 'No pudimos encontrar tus datos de usuario'
          })
        }
      }
    }

    getUserData()
    return () => authListener()
  }, [authUser])

  return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>
}

export default SessionProvider
