import React from 'react'
import { Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './styles.css'

const { Text } = Typography

export default class Loading extends React.Component {
  render = () => {
    return (
      <main className='spinner'>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        <Text className='loading-text'>Cargando...</Text>
      </main>
    )
  }
}
