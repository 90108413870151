import { Service } from './types'
import ServiceUtils from './utils'

export default class ServicesService {
  db: any
  utils: ServiceUtils

  constructor(app: any) {
    this.db = app.firestore()
    this.utils = new ServiceUtils(app)
  }

  service = async (id: string): Promise<Service> => {
    let data = null
    try {
      data = await this.utils.getDocumentData('services', id)
    } catch (error) {
      console.error(error)
    }
    return data
  }

  services = async (): Promise<Service[]> => this.db.collection('services')
}
