export const ROOT = 'https://pixelcode.cl'
export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const PROFILE = '/profile'
export const DASHBOARD = '/dashboard'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/forgot'
export const SERVICES = '/services'
export const GODOCU = '/services/godocu'
export const GODOCU_PDF = '/services/godocu/getdocument/:documentId'
export const GODOCU_CATEGORY_PDF = '/services/godocu/getcategory/:categoryId'
export const NOT_AUTHORIZED = '/not-authorized'
