import { createContext } from 'react'
import { Auth, Clients, Documents, Services, Storage, Users, Utils } from './Firebase'
import { Session } from './services/types'

export const FirebaseServices = {
  Auth,
  Clients,
  Documents,
  Services,
  Storage,
  Users,
  Utils
}

export const FirebaseServicesContext = createContext(FirebaseServices)
export const SessionContext = createContext<Partial<Session>>({})
